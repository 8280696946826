.viewbutton {
  font-size: 16px;
  color: #db1b0d;
  letter-spacing: 0px;
  line-height: 24px;
  border-radius: 5px;
  background: none;
  border: 1px solid #db1b0d;
  width: 143px;
  height: 42px;
  box-shadow: inset 0 0 0 0 rgb(219, 27, 13);
  transition: box-shadow 0.3s ease;
}
.viewbuttonalink {
  text-decoration: none;
  color: #db1b0d;
}
.viewbuttonalink:hover {
  color: #db1b0d;
}
.viewbutton:hover,
.viewbutton:focus {
  box-shadow: inset 12em 0 0 0 rgb(219, 27, 13);
  color: #fff;
}
.scrollparent {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1399px) {
  .scrollparent {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.scrolldiv {
  display: inline-block;
}
.acheivername {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-family: Switzer-Bold;
}
.acheiverrank {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
.sectionredtitle {
  font-family: Switzer-Bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #db1b0d;
}
@media only screen and (max-width: 500px) {
  .acheivername {
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .acheiverrank {
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .sectionredtitle {
    font-size: 13px !important;
  }
}
